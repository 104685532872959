// @ts-nocheck


import {
   __,
   ap,
   append,
   assocPath,
   compose,
   evolve,
   hasPath,
   ifElse,
   includes,
   isNotNil,
   map,
   mergeDeepLeft,
   mergeLeft,
   path,
   pipe,
   prop,
   propEq,
   propSatisfies,
   tap,
   update,
   when,
} from 'ramda';

import { getJobTypeAndResourceId } from '@/utils/accessors';
import { hasResourceId } from '@/utils/comparators';
import { computeTaskUnifiedStatus, dataStatePath, dataStates, errorTypes, jobTypeKeys } from '@/utils/constants';
import { GQLError } from '@/utils/customErrors';
import { rejectByResourceID } from '@/utils/filters';
import { applyToJobsInWorkspace, updateJobs } from '@/utils/formatters';

import { trackDeletion, trackTermination } from '@/analytics/events';
import { refinerTrackRemoteDeletion, refinerTrackRemoteTermination } from '@/analytics/refiner';

import { errors } from './errors';
import services from './services';
import { store } from './state';

// Creates a function that tests whether a given job object matches any of the id's in the given jobResourceIds array
const containsJob = (jobResourceIds) => propSatisfies(includes(__, jobResourceIds), 'resourceId');

const setTaskStatus = (job, status) =>
   compose(
      mergeLeft({
         unifiedStatus: status,
      }),
      path(['tasks', '0']),
   )(job);
const updateJobWithTasks = (status) => (job) => {
   const updatedTask = setTaskStatus(job, status);
   return mergeLeft({ tasks: [updatedTask] }, job);
};
const updateJob = ({ jobResourceIds, status }) => when(containsJob(jobResourceIds), updateJobWithTasks(status));

const updateJobsList = compose(applyToJobsInWorkspace, updateJob);

const formatResponseForPresent = pipe(
   path(['data', 'terminateJob', 'job']),
   updateJobs,
   assocPath(['workspace', 'jobs'], __, { workspace: {} }),
   evolve,
);

const terminationTrackers = (res) => {
   const { jobType } = path(['data', 'terminateJob', 'job', 'jobDefinition'], res);
   const { id } = path(['data', 'terminateJob', 'job'], res);
   trackTermination(window.San, { jobType, id });

   if (jobType === jobTypeKeys.remote) {
      refinerTrackRemoteTermination();
   }
};

const deletionTrackers = (job) => {
   const { jobTypeName, resourceId } = getJobTypeAndResourceId(job);
   trackDeletion(window.San, { jobTypeName, resourceId });
   if (jobTypeName === jobTypeKeys.remote) {
      refinerTrackRemoteDeletion();
   }
};

const updateTaskOrJobStatus = (id, status) =>
   map(
      when(
         propEq(id, 'resourceId'),
         ifElse(
            hasPath(['tasks']),
            mergeDeepLeft({ tasks: update(0, setTaskStatus(__, status), prop('tasks', __)) }),
            mergeDeepLeft({ status }),
         ),
      ),
   );

export const rawActions = (present) => ({
   loadJobs: (client, poll = false) => {
      present({ op: 'replace', value: dataStates.loading, path: dataStatePath });

      return services
         .getWorkspace(client)
         .then((workspace) => {
            present(
               [
                  { op: 'replace', value: workspace, path: '/workspace' },
                  { op: 'replace', value: dataStates.ready, path: dataStatePath },
               ],
               { client, poll },
            );
         })
         .catch(() => {
            present({ op: 'replace', value: dataStates.error, path: dataStatePath }, { client, poll });
         });
   },

   deleteJobs: (client, jobs) => {
      const jobResourceIds = jobs.map(prop('resourceId'));
      present(updateJobsList({ jobResourceIds, status: computeTaskUnifiedStatus.Deleting }));

      return services
         .deleteJobs(client, jobResourceIds)
         .then(() => {
            // TODO - this is some derived data, ideally done in the nap or a separate action
            // called from the nap
            ap([store.actions.deselectJob, deletionTrackers], jobs);
            present(updateJobsList({ jobResourceIds, status: computeTaskUnifiedStatus.Deleted }));
         })
         .catch((err) => {
            // TODO - updating the 'errorstate' for compatibility, but this should
            // be reviewed and a better solution applied
            // TODO : this action is unreachable for its purpose,
            // an action error will not throw but return an errors key in the response object
            errors.updateErrorsState(new GQLError(errorTypes.jobDeleteError, err));
         });
   },

   addJob: (job) => present({ op: 'add', value: job, path: '/workspace/jobs/-' }),

   updateJob: compose(present, applyToJobsInWorkspace),

   terminateJob: (client, job) => {
      present(updateJobsList({ jobResourceIds: [job.resourceId], status: computeTaskUnifiedStatus.Terminating }));

      return services
         .terminateJob(client, job.resourceId)
         .then(tap(terminationTrackers))
         .then(formatResponseForPresent)
         .then(() =>
            present(updateJobsList({ jobResourceIds: [job.resourceId], status: computeTaskUnifiedStatus.Terminated })),
         )
         .catch(errors.updateErrorsState);
   },

   selectJob: (job) => {
      present((state) => {
         const selection = ifElse(
            hasResourceId(job.resourceId),
            rejectByResourceID(job.resourceId),
            append(job),
         )(state.selection);

         return mergeLeft({ selection }, state);
      });
   },

   deselectJob: (job) => {
      present((state) => {
         const selection = rejectByResourceID(job.resourceId)(state.selection);
         return mergeLeft({ selection }, state);
      });
   },

   clearSelection: () => {
      present({ op: 'replace', value: [], path: '/selection' });
   },

   setWarnUserInfo: (value) => {
      sessionStorage.setItem('warnUserRemoteSession', value);
      present({ op: 'replace', value, path: '/warnUserRemoteSession/userWantsToBeWarned' });
   },

   getWarnInfo: () => {
      const warnUser = JSON.parse(sessionStorage.getItem('warnUserRemoteSession'));

      if (isNotNil(warnUser)) {
         present({ op: 'replace', value: warnUser, path: '/warnUserRemoteSession/userWantsToBeWarned' });
      }
   },

   test: {
      initWorkspaceState: (data) => {
         present({ op: 'replace', value: data, path: '' });
      },
   },

   setJobStatus: (id, status) => {
      present(
         evolve({
            workspace: {
               jobs: updateTaskOrJobStatus(id, status),
            },
         }),
      );
   },
});
